<template>
    <div class="checkoutBillToPax">
        <div class="checkout-resume-row billToPaxRow">
           <strong class="subBlockTitle">Dati di fatturazione</strong>
        </div>
        <div class="checkout-resume-row billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestFiscalCode" ref="guestFiscalCode" type="text" placeholder="Codice Fiscale" autocomplete="fiscal-code" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestFiscalCode')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Codice fiscale'" />
            </div>
        </div>
        <div class="checkout-resume-row billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestAddress" ref="guestAddress" type="text" placeholder="Indirizzo" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestAddress')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Indirizzo e civico'" />
            </div>
        </div>
        <div class="checkout-resume-row billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestCity" ref="guestCity" type="text" placeholder="Città" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestCity')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Città'" />
            </div>
        </div>
        <div class="checkout-resume-row billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestPostalCode" ref="guestPostalCode" type="text" placeholder="CAP" />
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestPostalCode')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Codice avviamento postale'" />
            </div>
        </div>
        <div class="checkout-resume-row billToPaxRow">
            <div class="customInputBlock">
                <b-form-input :size="inputSize" @input="validateBlock()" v-model="guestProvince" ref="guestProvince" type="text" placeholder="Provincia" autocomplete="province"/>
                <span class="formError"></span>
                <span class="formClear" @click="clearField('guestProvince')"></span>
                <SvgInfoCircle class="svgInfo cursor-pointer" v-b-popover.hover.top="'Sigla provincia'" />
            </div>
        </div>
    </div>
</template>

<script>
import SvgInfoCircle from './../../svg/info-circle'

export default {
    name: 'checkoutBillToPax',
    components:
    {
        SvgInfoCircle,
    },
    props:
    {
        inputSize: String
    },
    mounted()
    {

        let sessionData = this.$session.get("sidebarBlocks")

        if(sessionData !== undefined)
        {
            this.guestAddress = sessionData.checkoutBillToPax.fields.guestAddress
            this.guestCity = sessionData.checkoutBillToPax.fields.guestCity
            this.guestPostalCode = sessionData.checkoutBillToPax.fields.guestPostalCode
            this.guestProvince = sessionData.checkoutBillToPax.fields.guestProvince
            this.guestFiscalCode = sessionData.checkoutBillToPax.fields.guestFiscalCode
        }

        this.validateBlock()
    },
    data()
    {
        return {
            guestAddress: (process.env.VUE_APP_MODE == 'development')? 'via Clementina, 18' : '',
            guestCity: (process.env.VUE_APP_MODE == 'development')? 'Jesi' : '',
            guestPostalCode: (process.env.VUE_APP_MODE == 'development')? '60035' : '',
            guestProvince: (process.env.VUE_APP_MODE == 'development')? 'AN' : '',
            guestFiscalCode: (process.env.VUE_APP_MODE == 'development')? 'GRNMHL86L11C615X' : '',
        }
    },
    computed:
    {
    },
    methods:
    {
        clearField(ref)
        {
            this[ref] = ''
            this.$refs[ref].focus()
            this.validateBlock()
        },
        validateBlock()
        {
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['guestAddress'],
                    type: 'address',
                    value: this.guestAddress,
                },
                {
                    ref: this.$refs['guestCity'],
                    type: 'city',
                    value: this.guestCity,
                },
                {
                    ref: this.$refs['guestPostalCode'],
                    type: 'postalCode',
                    value: this.guestPostalCode,
                },
                {
                    ref: this.$refs['guestProvince'],
                    type: 'province',
                    value: this.guestProvince,
                },
                {
                    ref: this.$refs['guestFiscalCode'],
                    type: 'fiscalCode',
                    value: this.guestFiscalCode,
                },
            ])

            this.$emit('sidebarBlockChanged',{
                name: 'checkoutBillToPax',
                status: valid,
                fields: {
                    'guestAddress': this.guestAddress,
                    'guestCity': this.guestCity,
                    'guestPostalCode': this.guestPostalCode,
                    'guestProvince': this.guestProvince,
                    'guestFiscalCode': this.guestFiscalCode,
                }
            })

            return valid
        },
    }
}
</script>

<style lang="scss">
    
    
    

    .checkoutBillToPax
    {
        .subBlockTitle
        {
            color:$black;
            display:block;
            width:100%;
            text-align:center;
            margin-bottom: 1.5rem;
        }        
    }
</style>