<template>
  <div class="otp" v-if="required">

    <b-button
      class="btn btn-block"
      :size="button_size"
      :variant="button_variant"
      v-b-modal="'modal-otp'"
      :disabled="disabled"
      @click="() => { if(this.status == 'REQUIRED') $emit('otp-event', 'REQUIRED_CTA_CLICK')}"
      >
      Conferma
    </b-button>

    <b-modal :id="'modal-otp'" :title="display_name + ' vorremmo essere sicuri che sei tu'" size="md">

      <span class="otp--modal--description">
        Per garantire la sicurezza del tuo credito welfare invieremo una password temporanea 
        <span v-if="by == 'MAIL'">al tuo indirizzo email <strong>{{sent_to}}</strong></span>
        <span v-else>al tuo numero di telefono <strong>{{sent_to}}</strong></span>
        <br/><br/>
        Richiedila e potrai inserirla in questa finestra per completare la prenotazione.
      </span>

      <div v-if="loading" class="text-center mt-3">
        <b-spinner :variant="button_variant" />
      </div>

      <div v-else class="otp--modal-wrapper">
        <div class="otp--modal-add-code text-center mt-3" v-if="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID'">
          
          <span class="otp--modal-notify-mail">Ti abbiamo appena inviato la password temporanea.<br/> Controlla la tua email.</span>

          <div class="otp--modal-code-wrapper">
            <span class="otp--modal-prefix">{{prefix}}- </span>
            <b-form-input @input="validateCodeInput()" v-model.trim="code" ref="otpCode" placeholder="Inserisci la password" type="text" class="w-75 otp--modal-input"></b-form-input>
            <span class="formError"></span>
          </div>
          
          <b-button
            :size="button_size"
            :variant="button_variant"
            :disabled="code.length < 6 || loading"
            @click="validate_otp()"
            class="mt-2"
            >Prenota
          </b-button>

          <div v-if="msg != ''" class="otp--modal-text mb-4 mt-2 font-weight-bold">{{msg}}</div>
          
          <div class="otp--info text-left mt-4">
            <div class="font-italic" v-if="countdown > 0">Potrai richiedere una nuova password tra {{countdown}} secondi</div>
            <strong v-if="by == 'MAIL'">Non trovi la nostra email? Controlla anche la cartella spam.</strong>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="otp--modal-requestcode d-flex w-100" :class="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID' ? 'justify-content-end' : 'justify-content-center'" >
          <b-button
            :size="button_size"
            :variant="button_variant"
            :disabled="countdown > 0"
            @click="request_otp()"
            >
            <span v-html="status == 'OTP_SENT' || status == 'RATE_LIMITED' || status == 'NOT_VALID' ? 'Richiedi un\'altra password' : 'Richiedi password'"></span>
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../api'
export default {
  name: "otp",
  components: {},
  props: {
    disabled:{
      type: Boolean,
      default: false,
    },
    button_variant: {
      type: String,
      default: 'primary',
    },
    button_size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required: Vue.prototype.$config.integration.otpRequired,
      code: '',
      loading: false,
      status: '',
      msg: '',
      display_name: '',
      sent_to : '',
      countdown: 0,
      by: '',
      prefix: ''
      
      /*
      - **NOT_REQUIRED**: otp non necessario, nulla da fare
      - **REQUIRED**: otp deve essere richiesto (pulsante invia + input per codice)
      - **OTP_SENT**: otp inviato
      - **RATE_LIMITED**: troppe chiamate "new"
      - **VALID**: inserito otp valido, sessione autenticata
      - **ALREADY_VALIDATED**: già autenticato
      */
    }
  },

  mounted() {
    if(this.required == false) return this.$emit('otp-check', false)

    Api
    .otp({})
    .then(
        (response) => {
          this.status = response.status
          this.display_name = response.displayName
          this.sent_to = response.sentTo
          this.by = response.by

          if(this.status == 'VALID') {
            this.$emit('otp-check', true)
          }
          if(this.status == 'ALREADY_VALIDATED') {
            this.$emit('otp-check', true)
          }
          if(this.status == 'NOT_REQUIRED') {
            this.$emit('otp-check', true)
          }

          this.$emit('otp-event', this.status)

          this.loading = false
        },
        (error) => {
          this.log('request otp error')
          this.log(error)
          this.$emit('otp-check', true)
          this.$emit('otp-event', 'INIT_ERROR')

          this.status = 'ERROR'
          this.loading = false
        }
    )

    this.validateCodeInput()
  },

  methods: {
    
    countdown_timer() {
        if (this.countdown > 0) {
            setTimeout(() => {
                this.countdown -= 1
                this.countdown_timer()
            }, 1000)
        }
    },

    request_otp() {
      this.loading = true
      this.code = ''
      this.msg = ''

      this.$emit('otp-event', 'REQUEST')

      Api
      .otp({
        new: true,
      })
      .then(
        (response) => {
          this.status = response.status
          this.loading = false
          this.prefix = response.prefix
          this.countdown = response.wait ? response.wait : 60
          this.countdown_timer()
          this.$emit('otp-event', this.status)
        },
        (error) => {
          this.log('request otp error')
          this.log(error)
          this.$emit('otp-check', true)

          this.$emit('otp-event', 'REQUEST_ERROR')
          this.status = 'ERROR'

          this.loading = false
        }
      )
    },
    
    validate_otp() {
      this.loading = true
      this.$emit('otp-event', 'VALIDATING')

      Api
      .otp({
        otp: this.code,
      })
      .then(
        (response) => {
          this.status = response.status
          this.msg = response.msg

          if(this.status == 'VALID') {
            this.$emit('otp-check', true)
            this.$emit('otp-purchase', true)
          }

          this.$emit('otp-event', this.status)

          this.loading = false
        },
        (error) => {
          this.log('validate otp error')
          this.log(error)
          this.$emit('otp-check', true)
          this.$emit('otp-event', 'VALIDATING_ERROR')
          this.status = 'ERROR'
          this.loading = false
        }
      )
    },

    validateCodeInput()
        {
            let valid = this.mixinValidateFields([
                {
                    ref: this.$refs['otpCode'],
                    type: 'otp',
                    value: this.code,
                }
            ])
            return valid
        },
  }
}
</script>

<style lang="scss">

.modal {

  &-title {
    font-size: 16px;
  }

  &-body {
    padding: 20px !important;
  }
  
}

.otp {

  &--modal-requestcode.justify-content-end {

    .btn {
      background-color: transparent !important;
      color: $text !important;
      border-color: transparent !important;
      box-shadow: none !important;
      text-decoration: underline;
      font-size: 13px;

      &.disabled {
        opacity: .3;
        text-decoration: none;
      }
    }
  }

  &--info {
    font-size: 14px;
  }

  &--modal {

    &-notify-mail {
      font-size: 13px;
      display: block;
      margin-bottom: 20px;
    }

    &-prefix {
      margin-right: 10px;
      margin-left: -35px;
      font-style: italic;
      font-size: 18px;
      color: $gray-500;
    }

    &-text {
      color: $red;
    }

    &-code-wrapper {

      .formError {
        color: $red;
        font-size: 13px;
        display: block;
        margin-top: 8px;
        margin-bottom: 12px;
      }
    }

    &-prefix, &-input {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

[otp-wrap="otp--ok"] {
  pointer-events: none !important;
  filter: blur(2px) !important;
  opacity:0.6 !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
</style>