<template>
    <div id="sidebar-checkout">

        <b-row >
            <b-col sm="12">
                <!--<router-link class="nounderline" :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: this.property.name }}">-->
                <router-link class="nounderline" :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: this.bookingData.hotelName }}">
                    <BtnBack :text="'Torna indietro'" />
                </router-link>
                <br>
            </b-col>
        </b-row>

        <!-- credito welfare non sufficiente -->
        <div v-if="this.$route.name == 'checkout' && number(availability) > 0 && larger(number(bookingData.live_price), number(availability)) && this.$config.integration.integration !== 'jointly'">
            <b-alert variant="danger" class="text-center" show><b><span v-if="mixinIsWelfare()">Credito welfare</span><span v-else>Credito</span> non sufficiente!</b></b-alert>
            <div class="panel-tertiary">
                <div class="top">
                    <img :src="avatarSrc" />
                    <div>
                        <p class="name">{{displayName}}</p>
                        <p v-if="availability > 0" class="credit"><span v-if="mixinIsWelfare()">Credito welfare</span><span v-else>Credito</span> € {{mixinRoundTwoDigits(availability)}}</p>
                    </div>
                </div>

                <div class="panel-content">
                    <h5 class="panel-title">Riepilogo</h5>
                    
                    <div class="checkout-resume-block">
                        <div class="checkout-resume-row align-left mb-2">
                          <b>{{bookingData.roomDescription}}</b>
                        </div>
                        <div class="checkout-resume-row align-left pl-3 mb-2">
                          <div>
                            {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                            <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                            (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                          </div>
                        </div>
                        <div class="checkout-resume-row pl-4">
                            <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                            <span>
                                {{bookingData.totals.exclusive}} €
                            </span>
                        </div>
                        <div class="checkout-resume-row pl-4">
                            <span
                                v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                            </span>
                            <span>
                                {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                            </span>
                        </div>
                        <div class="checkout-resume-row pl-3">
                            <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                            </span>
                            <span>
                                {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                            </span>
                        </div>
                    </div>

                    <div class="checkout-resume-block">
                        <div class="checkout-resume-row">
                            <strong>Totale per il viaggio</strong>
                            <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                        </div>
                        <div class="d-flex justify-content-center">
                            <small>Totalmente saldato al momento della prenotazione</small>
                        </div>
                    </div>

                    <div class="checkout-resume-block" v-if="this.$route.name == 'checkout'">
                        <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax > 0">
                            <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                              Tassa di soggiorno
                              <SvgInfoCircle />
                            </span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                        </div>
                        <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee > 0">
                            <span>Spese di pulizia ed utenze</span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                        </div>
                        <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee > 0">
                            <span>Resort fee</span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <small>Pagabili in hotel al check-in o al check-out</small>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- credito welfare sufficiente -->
        <div v-else>
            <div class="panel-tertiary">
                <div v-if="!this.mixinIsPublic()" class="top">
                    <img :src="avatarSrc" />
                    <div>
                        <p class="name">{{displayName}}</p>
                        <p v-if="availability > 0" class="credit"><span v-if="mixinIsWelfare()">Credito welfare</span><span v-else>Credito</span> € {{mixinRoundTwoDigits(availability)}}</p>
                    </div>
                </div>
                <div class="panel-content">
                    <h5 class="panel-title">Riepilogo</h5>
                    
                    <div class="checkout-resume-block">
                        <div class="checkout-resume-row align-left mb-2">
                          <b>{{bookingData.roomDescription}}</b>
                        </div>
                        <div class="checkout-resume-row align-left pl-3 mb-2">
                          <div>
                            {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults > 1">adulti</span><span v-else>adulto</span>
                            <span v-if="bookingData.childs.length">, {{bookingData.childs.length}} <span v-if="bookingData.childs.length > 1">bambini</span><span v-else>bambino</span>
                            (<span v-for="(c,i) in bookingData.childs" v-bind:key="_uid+i">{{ c }}<span v-if="i<(bookingData.childs.length-1)">, </span></span> anni)</span>
                          </div>
                        </div>
                        <div class="checkout-resume-row pl-3">
                            <span>{{bookingData.nights}} <span v-if="bookingData.nights > 1">Notti</span><span v-else>Notte</span></span>
                            <span>
                                {{bookingData.totals.exclusive}} €
                            </span>
                        </div>
                        <div class="checkout-resume-row pl-3">
                            <span
                                v-b-popover.hover.top="'Questo addebito include gli importi stimati che il fornitore dei servizi di viaggio (ad esempio hotel, società di autonoleggio) paga in forma di tasse e/o le tasse che paghiamo alle autorità fiscali sulla tua prenotazione (incluse, a titolo esemplificativo, le imposte sulle vendite, di soggiorno o sul valore aggiunto). Può anche includere eventuali importi a noi addebitati per le tariffe resort, i supplementi per le pulizie e altre spese e/o un costo che noi, il fornitore dell\'hotel e/o il sito su cui hai prenotato tratteniamo come parte del compenso per i nostri servizi, che varia in base a fattori quali la posizione, l\'importo e la modalità di prenotazione. Per maggiori informazioni, consulta i termini e le condizioni.'"
                                id="info-taxes" class="cursor-pointer">Tasse ed oneri <SvgInfoCircle />
                            </span>
                            <span>
                                {{mixinRoundTwoDigits(bookingData.totals.inclusive - bookingData.totals.exclusive)*100/100}} €
                            </span>
                        </div>
                        <div class="checkout-resume-row pl-3">
                            <span v-b-popover.hover.top="'Costi di gestione della tua prenotazione e per l\'assistenza multicanale e dedicata ' + site.brand + '.'" id="info-taxes" class="cursor-pointer">{{ site.brand }} service charge <SvgInfoCircle />
                            </span>
                            <span>
                                {{mixinRoundTwoDigits(bookingData.live_price - bookingData.totals.inclusive)*100/100}} €
                            </span>
                        </div>
                    </div>

                    <div class="checkout-resume-block">
                        <div class="checkout-resume-row">
                            <strong>Totale per il viaggio</strong>
                            <strong>{{mixinRoundTwoDigits(bookingData.live_price)}} €</strong>
                        </div>

                        <!-- SOLO CONFIRM DETTAGLIO PAYMENTS USATI -->
                        <div v-if="this.$route.name == 'confirm' && bookingData.payments.welfare.amount > 0" class="checkout-resume-row">
                          <span>Saldato tramite <span v-if="mixinIsWelfare()">credito welfare</span><span v-else>credito</span></span>
                          <span>{{mixinRoundTwoDigits(bookingData.payments.welfare.amount)}} €</span>
                        </div>

                        <div v-if="this.$route.name == 'confirm' && bookingData.payments.etc.amount > 0" class="checkout-resume-row">
                          <span>Saldato tramite Ticket Compliments &reg;</span>
                          <span>{{mixinRoundTwoDigits(bookingData.payments.etc.amount)}} €</span>
                        </div>

                        <div v-if="this.$route.name == 'confirm' && bookingData.payments.cc.amount > 0" class="checkout-resume-row">
                          <span>Saldato tramite carta di credito</span>
                          <span>{{mixinRoundTwoDigits(bookingData.payments.cc.amount)}} €</span>
                        </div>

                        <div v-if="this.$route.name == 'confirm' && bookingData.payments.discountCodes.amount > 0" class="checkout-resume-row">
                          <span>Saldato tramite codici sconto</span>
                          <span>{{mixinRoundTwoDigits(bookingData.payments.discountCodes.amount)}} €</span>
                        </div>
                        <!-- solo confirm -->


                        <div class="d-flex justify-content-center">
                            <small>Totalmente saldato al momento della prenotazione</small>
                        </div>


                        <!--
                          && availability - bookingData.live_price > 0
                          serve per gestire il caso jointly
                          in breve, nascondo l'estrattoconto del credito se il saldo è negativo
                          (il che significa che si sta acquistando una camera più costosa del proprio credito
                          quindi per intero con carta di credito)
                        -->
                        <!-- checkout only -->
                        <div v-if="this.$route.name == 'checkout' && availability > 0 && availability - bookingData.live_price > 0" class="checkout-resume-row">
                            <span><span v-if="mixinIsWelfare()">Credito welfare</span><span v-else>Credito</span> disponibile</span>
                            <span>{{mixinRoundTwoDigits(availability)}} €</span>
                        </div>
                        <!-- checkout only -->
                        <div v-if="this.$route.name == 'checkout' && availability > 0 && availability - bookingData.live_price > 0" class="checkout-resume-row">
                            <span>Credito residuo dopo il pagamento</span>
                            <span>{{mixinRoundTwoDigits(availability - bookingData.live_price)}} €</span>
                        </div>



                    </div>

                    <div class="checkout-resume-block" v-if="this.$route.name == 'checkout' && fees != 0">
                        <strong>Costi obbligatori non inclusi</strong>
                        <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_tax !== 0">
                            <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                              Tassa di soggiorno
                              <SvgInfoCircle />
                            </span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                        </div>
                        <div class="checkout-resume-row" v-if="bookingData.totals.mandatory_fee !== 0">
                            <span>Spese di pulizia ed utenze</span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                        </div>
                        <div class="checkout-resume-row" v-if="bookingData.totals.resort_fee !== 0">
                            <span>Resort fee</span>
                            <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                        </div>
                        <div class="d-flex justify-content-center">
                            <small>Da pagare in struttura al check-in</small>
                        </div>
                    </div>
                    <div class="sidebarResumeBlock" v-else-if="this.$route.name == 'checkout'">

                      <strong>Costi obbligatori non inclusi</strong>
                      &nbsp;
                      <div class="d-flex justify-content-center">
                        La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
                      </div>

                    </div>
                </div>
            </div>
            
            <!-- checkout only -->
            <div class="panel-white" v-if="this.$route.name == 'checkout'">    
                <div class="panel-content">
                    <h5 class="panel-title">Conferma della prenotazione</h5>
                    <b-form-group id="checkout-form" :valid-feedback="validFeedback()">

                        <div v-show="allowCustomBeneficiary">
                            <label class="cursor-pointer" v-b-popover.hover.top="'Scrivi il nome del viaggiatore principale'">
                                <SvgInfoCircle /> Nome del viaggiatore principale
                            </label>
                            <b-form-input ref="customBeneficiaryFirstname" :required="true" v-model="customBeneficiaryFirstname" type="text" placeholder="Mario Rossi" />
                            <label class="cursor-pointer" v-b-popover.hover.top="'Scrivi il cognome del viaggiatore principale'">
                                <SvgInfoCircle /> Cognome del viaggiatore principale
                            </label>
                            <b-form-input ref="customBeneficiaryLastname" :required="true" v-model="customBeneficiaryLastname" type="text" placeholder="Mario Rossi" />
                            <label class="cursor-pointer" v-b-popover.hover.top="'Il viaggiatore deve essere uno dei possibili beneficiari del tuo piano welfare, quali i familiari'">
                                <SvgInfoCircle /> Scegli il grado di parentela
                            </label>
                            <b-form-select ref="checkoutGuestRelation" :required="true" v-model="guestRelation" :options="beneficiaryRelations"></b-form-select>        
                            <label class="cursor-pointer" v-b-popover.hover.top="'Codice fiscale del viaggiatore principale'">
                                <SvgInfoCircle /> Codice fiscale del viaggiatore principale
                            </label>
                            <b-form-input ref="customBeneficiaryFiscalCode" :required="true" v-model="customBeneficiaryFiscalCode" type="text" placeholder="codice fiscale" />
                        </div>
                        <div v-show="!allowCustomBeneficiary">
                            <label class="cursor-pointer" v-b-popover.hover.top="'Quella che vedi quì è la lista dei possibili beneficiari del tuo credito welfare così come inserita nel tuo portale welfare. Se il nome del viaggiatore non risulta tra i beneficiari devi tornare sul portale welfare, inserirlo tra i beneficiari e poi fare di nuovo dall\'inizio il processo di prenotazione su ' + site.brand">
                                <SvgInfoCircle /> Scegli il nome del viaggiatore principale
                            </label>
                            <b-form-select ref="checkoutBeneficiaries" :required="true" v-model="beneficiaryId" :options="beneficiaries"></b-form-select>        
                        </div>
                        

                        <label class="cursor-pointer" v-b-popover.hover.top="'Il voucher per fare check-in in hotel verrà inviato a questo indirizzo email'">
                          <SvgInfoCircle /> Email del viaggiatore
                        </label>                    
                        <b-form-input ref="checkoutEmail" :required="true" v-model="guestEmail" type="email" placeholder="Email" />
                        

                        <label class="cursor-pointer" v-b-popover.hover.top="'Te la facciamo riscrivere per scoprire eventuali errori di digitazione'">
                          <SvgInfoCircle /> Conferma email del viaggiatore <!--<SvgInfoCircle />-->
                        </label>
                        <b-form-input ref="checkoutEmailConfirm" :required="true" v-model="guestEmailConf" type="email" placeholder="Conferma email" />
                        

                        <label class="cursor-pointer" v-b-popover.hover.top="'Avere il tuo numero ci permetterà di contattarti velocemente in caso di qualsiasi problema'">
                          <SvgInfoCircle /> Numero di cellulare del viaggiatore <!--<SvgInfoCircle />-->
                        </label>
                        <b-form-input ref="checkoutPhone" :required="true" v-model="guestPhone" type="tel" placeholder="Telefono" autocomplete="tel" />

                        <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                        <CheckoutBillToPax v-if="sidebarBlocks.checkoutBillToPax.initialize" v-on:sidebarBlockChanged="sidebarBlockChange" />

                        <div class="privacy-box">
                            <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                            <label for="checkbox-privacy">
                                <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                    <span>Ho preso visione delle condizioni d'uso e le accetto</span>
                                </router-link>
                            </label>
                        </div>

                        <br>

                        <div class="privacy-box">
                            <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                            <label for="checkbox-gdpr">
                                <p>
                                    <a :href="site.urlPrivacy" target="_blank">Ho preso visione delle condizioni di trattamento dei dati e le accetto</a>
                                </p>
                            </label>
                        </div>

                        <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                            <label for="checkbox-gdpr">
                                <!--<p class="checkboxInfo" v-html="disclaimerCheckout"></p>-->
                                <p v-html="disclaimerCheckout"></p>
                            </label>
                        </div>

                        <Spinner v-if="loading" v-bind:msg="'Prenotazione in corso... non abbandonare o ricaricare la pagina!'" />
                        <div v-else>
                            <Otp
                                v-if="otp_required && (otp_check == false)"
                                :button_size="'md'"
                                :button_variant="'tertiary'"
                                @otp-check="(value) => { otp_check = value }"
                                @otp-event="(value) => { this.trackOtpEvent(value)}"
                                @otp-purchase="checkoutConfirm()"
                                :disabled="validFeedback() !== 'ok'"
                            />
                            <b-button v-if="otp_required == false || (otp_required && otp_check)" class="btn btn-block" :disabled="validFeedback() !== 'ok'" @click="checkoutConfirm">PRENOTA!</b-button>
                        </div>
                    </b-form-group>
                </div>
            </div>

            <!-- confirm only -->
            <div v-if="this.$route.name == 'confirm'">
                <a href="/" class="btn btn-block btn-secondary">Torna alla Home</a>
                <!--
                TODO rendere contestuale
                <br>
                <a href="#" class="btn btn-block btn-secondary">Torna a Easy Welfare</a>-->
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import SvgInfoCircle from './../../svg/info-circle'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import Otp from './../../blocks/otp'
import {number, larger} from "mathjs";

export default {
    name: 'sidebar-checkout',
    components:
    {
        Spinner,
        SvgInfoCircle,
        BtnBack,
        CheckoutBillToPax, /* SIDEBAR REFACTOR */
        Otp
    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() { return this.$store.state.integration.availability },
            set(value) { this.mixinSendMutation('setAvailabilty',value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
        geo: {
          get() { return this.$store.state.geo },
          set() {}
        },
    },
    data() {        
        return {
            status: 'not_accepted',
            //availability: Vue.prototype.$config.integration.availability,
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            loading: false,
            guestFirstName: '',
            guestLastName: '',
            guestEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestEmailConf: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestPhone: (process.env.VUE_APP_MODE == 'development')? '3934288350' : '',
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            beneficiaryId: '', // default vuoto, non cambiare, viene usato al checkout, setBeneficiaryById
            beneficiaries: [],
            fees: this.$store.state.bookingData.totals.mandatory_tax + this.$store.state.bookingData.totals.mandatory_fee + this.$store.state.bookingData.totals.resort_fee,
            allowCustomBeneficiary: false,
            customBeneficiaryFirstname: '',
            customBeneficiaryLastname: '',
            customBeneficiaryFiscalCode: '',
            beneficiaryRelations: [],
            guestRelation: 0,
            otp_check: false,
            otp_required: (Vue.prototype.$config.integration && Vue.prototype.$config.integration.otpRequired)? Vue.prototype.$config.integration.otpRequired : false,

            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutBillToPax: {
                    initialize: this.mixinCanBillToPax(),   // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    status: false,                          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}                              // campi che vengono eventualmente inviati a bookingData
                }
            },
            site: Vue.prototype.$config.site,
        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {
        let _this = this

        let parameters = {
            token: _this.token,
            routeName: this.$route.name,//Router.history.current.name.
        }

        if(_this.token && _this.$route.name == 'checkout')
        {
            _this.loading = true
            Api
            .getBeneficiary(parameters)
            .then((data) => {
                _this.allowCustomBeneficiary = data.allowCustomBeneficiary
                let bens = Object.values(data.beneficiaries)
                bens.forEach(ben => {
                    _this.beneficiaries.push({
                        value: ben.id,
                        name: ben.name,
                        surname: ben.surname,
                        fiscalCode: ben.fiscalCode,
                        text: ben.name + ' ' + ben.surname
                    })
                })
                _this.beneficiaryId = (_this.beneficiaries.length !== 0 ? _this.beneficiaries[0].value : 0)
                if(_this.allowCustomBeneficiary)
                {
                    data.beneficiaryRelations.forEach((rel,key) => {
                        _this.beneficiaryRelations.push({
                            value: key,
                            name: rel,
                            text: rel,
                        })
                    })

                    let customBen = bens[Object.keys(bens)[0]]
                    _this.customBeneficiaryFirstname = customBen.name
                    _this.customBeneficiaryLastname = customBen.surname
                    _this.beneficiaryId = customBen.id
                    _this.customBeneficiaryFiscalCode = customBen.fiscalCode
                    _this.guestEmail = customBen.email
                    _this.guestEmailConf = customBen.email
                }
                _this.loading = false
            })
        }
    },
    methods:
    {
      number,
      larger,
        /* SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto */
        sidebarBlockChange(e){
            let _this = this
            _this.sidebarBlocks[e.name].fields = e.fields
            _this.sidebarBlocks[e.name].status = e.status
            this.validFeedback()
        },        
        validateField(ref, invalid)
        {
            if(invalid)
            {
                ref.$el.classList.add('invalid')
                return false
            }
            ref.$el.classList.remove('invalid')
            return true
        },
        validFeedback()
        {
            // tolti questi 2 check in quanto il nome arriva dai beneficiari
            //if (this.guestFirstName.length < 4) return 'ko'
            //if (this.guestLastName.length < 4) return 'ko'


            if(Object.keys(this.$refs).length == 0) return 'ko'

            let check = true

            check = check && this.validateField(this.$refs.checkoutEmail, this.guestEmail.length < 4)

            check = check && this.validateField(this.$refs.checkoutEmailConfirm, this.guestEmail !== this.guestEmailConf)


            check = check && this.validateField(this.$refs.checkoutPhone, this.guestPhone.length < 4)

            check = check && this.validateField(this.$refs.checkoutPrivacy, !this.guestPrivacy)

            check = check && this.validateField(this.$refs.checkoutGdpr, !this.guestGdpr)

            // beneficiaryId può anche essere 0
            check = check && this.validateField(this.$refs.checkoutBeneficiaries, !(this.beneficiaryId || this.beneficiaryId === 0)? true : false)

            check = check && this.validateField(this.$refs.checkoutEmail, !this.validEmail(this.guestEmail))


            if(this.allowCustomBeneficiary)
            {
                check = check && this.validateField(this.$refs.customBeneficiaryFirstname, this.customBeneficiaryFirstname.length < 2)
                check = check && this.validateField(this.$refs.customBeneficiaryLastname, this.customBeneficiaryLastname.length < 2)
                check = check && this.validateField(this.$refs.customBeneficiaryFiscalCode, this.customBeneficiaryFiscalCode.length !== 16)
                check = check && this.validateField(this.$refs.checkoutGuestRelation, this.guestRelation < 0)
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                check = check && this.sidebarBlocks.checkoutBillToPax.status
            }

            return (check ? 'ok' : 'ko')

        },
        checkoutConfirm()
        {
            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            _this.loading = true

            // PAGAMENTO TUTTO WELFARE
            bookingData.payments.welfare.amount = bookingData.live_price

            parameters.bookingData = bookingData
            parameters.token = _this.token
            //parameters.guestFirstName = _this.guestFirstName
            //parameters.guestLastName = _this.guestLastName
            parameters.guestFirstName = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].name
            parameters.guestLastName = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].surname
            parameters.guestFiscalCode = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].fiscalCode
            parameters.guestEmail = _this.guestEmail
            parameters.guestEmailConf = _this.guestEmailConf
            parameters.guestPhone = _this.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.guestGdpr = _this.guestGdpr

            if(!_this.allowCustomBeneficiary)
            {
                parameters.beneficiaryId = _this.beneficiaryId
            }

            if(_this.allowCustomBeneficiary)
            {
                //parameters.customBeneficiaryFiscalCode = _this.customBeneficiaryFiscalCode                
                parameters.guestFiscalCode = _this.customBeneficiaryFiscalCode
                parameters.guestFirstName = _this.customBeneficiaryFirstname
                parameters.guestLastName = _this.customBeneficiaryLastname
                parameters.guestRelation = _this.beneficiaryRelations[_this.guestRelation].text
            }

            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode
            }


            Api
                .checkoutLivebooking(parameters)
                .then((response) => {
                    window.console.log('then')

                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)

                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId

                        return _this.checkoutConfirm()
                    }

                    _this.loading = false

                    if(response.status == 200)
                    {

                        if(_this.$gtm){

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedCheckout',
                            //label: Vue.prototype.$config.integration.email,
                            value: _this.bookingData.live_price,
                          });

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedCheckout',
                            label: Vue.prototype.$config.integration.company,
                            value: _this.bookingData.live_price,
                          });


                          let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                                                        ? _this.geo.inSalabamId
                                                        : (_this.geo.matchLevel == 'property')
                                                            ? _this.geo.propertyId
                                                            : 0;

                          _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'confirmedCheckoutSearch',
                            action: _this.geo.matchLevel,
                            label: _this.geo.label,
                            value: eventGeoExtraValue,
                          });


                          this.$gtm.trackEvent({
                            event: 'purchase',
                            ecommerce: {
                              currency: "EUR",
                              items: [
                                {
                                  item_id: _this.bookingData.hotelId + '-' + _this.bookingData.roomId + '-' + _this.bookingData.rateId,
                                  item_name: _this.bookingData.hotelName,
                                  item_brand: _this.site.brand,
                                  price: _this.bookingData.live_price,
                                  item_category: "",
                                  quantity: 1,
                                  //id: "",
                                  //google_business_vertical: "retail"
                                }
                              ],
                              transaction_id: response.data.confirmcode,
                              affiliation: "",
                              value: _this.bookingData.live_price, // Sum of (price * quantity) for all items.
                              tax: 0,
                              shipping: 0,
                              coupon: "",
                            }
                          });

                        }


                        if(window.LogRocket) window.LogRocket.track('confirmedCheckout')
                        if(window.clarity) window.clarity("set", "checkout", "confirmed")

                        this.$emit('sidebar-order-confirmed', {})

                        _this.mixinSendMutation('setAvailability',response.data.updateAvailabilityTo)
                        _this.$router.push({
                            name: 'confirm',
                            params: {
                                confirmcode: response.data.confirmcode,
                                itinerary_id: response.data.itinerary_id,
                                newAffiliateReferenceId: response.data.newAffiliateReferenceId,
                                redirectIfConfirmedRedirectTo: true
                            }
                        })
                    }
                })
        },
        validEmail(email){
            return /\S+@\S+\.\S+/.test(email)
        },
        trackOtpEvent(value){

          if(this.$gtm){
            this.$gtm.trackEvent({
              event: 'customEvent',
              category: 'ce',
              action: 'otp',
              label: value
            });
          }

          if(window.clarity) window.clarity('set', 'otp', value)
          if(window.LogRocket) window.LogRocket.track('otp_' + value)

        },
    }  
}
</script>

<style lang="scss">
    
    #sidebar-checkout {
        input, select
        {
            border:1px solid $smooth-black;
            margin-bottom:$grid-gutter-width/2;
            font-weight:400;
        }

        button
        {
            font-weight:600;
            font-size: 20px;
        }

        svg
        {
            width: 18px;
            margin-left: 3px;
            margin-bottom: 2px;
            margin-right: 10px;
        }

        label
        {
            font-weight:400;
            margin-bottom:4px;
        }

        .btn-back {
            @include media-breakpoint-down(md){
                justify-content: center;
            }
        }

        .panel-tertiary
        {
            p
            {
                margin-bottom:0;
                font-weight:normal;
            }

            .top
            {
                padding:$grid-gutter-width/3;
                display:flex;
                align-items: center;
                padding-bottom:$grid-gutter-width/3;
                border-bottom:1px solid $white;
                img {
                    width:44px;
                    height:44px;
                    margin-right:$grid-gutter-width/3;
                }
                p
                {
                    font-size:19px;
                }
                .name
                {
                    color:$white;
                    font-size: 16px;
                }
                .credit
                {
                    @include customize-color("secondary");
                    font-size: 14px;
                }
            }
            .panel-content
            {
                padding:$grid-gutter-width/3;
                color:$smooth-black;
                font-size:14px;
                color:$black;
                font-size:15px;
            }
        }

        .panel-title
        {
            margin-bottom:1.5rem;
        }

        .privacy-link
        {
            @include customize-color("secondary");
            text-decoration:underline;
            font-size: 13px;
        }

        .checkout-resume-block
        {
            font-size: 14px;
            font-weight:400;
            .checkout-resume-row
            {
                display:flex;
                justify-content:space-between;
                align-items: center;

                & > span {
                    
                    &:first-child {
                        flex-basis: 60%;
                        margin-bottom: 10px;
                    }
                    &:last-child {
                        flex-basis: 40%;
                        text-align: right;
                        font-weight: 500;
                    }
                }
            }
            strong
            {
                font-size: 16px;
            }
            small
            {
                display:block;
                margin-top:8px;
            }
            &:not(:last-child)
            {
                border-bottom:1px solid rgba($white,0.65);
                margin-bottom:0.75rem;
                padding-bottom:0.75rem;
                small
                {
                    margin-bottom:8px;
                }
            }
        }
        
        .privacy-box
        {
            display: flex;
            input
            {
                margin-top: 2px;
                z-index:999999999;
            }
            label[for=checkbox-privacy]
            {
                padding-left: 2px;
                line-height: 140%;
                margin-bottom:0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @include media-breakpoint-up(xl)
        {
            .panel .top,
            .panel .bottom
            {
                padding:$grid-gutter-width/3*2;
            }
            .panel .top
            {
                .name
                {
                    font-size: 18px;
                }
                .credit
                {
                    font-size: 18px;
                }
            }
            .panel .panel-content
            {
                padding:$grid-gutter-width/3*2;
                font-size:16px;
            }
        }

        @include media-breakpoint-down(sm)
        {
            .panel-tertiary
            {
                //margin-left:$grid-gutter-width/2;
                //margin-right:$grid-gutter-width/2;
                width:auto;
            }
        }

        @include media-breakpoint-only(lg)
        {
            .panel-tertiary
            {
                margin-right:$grid-gutter-width/2;
            }
            .panel-title
            {
                font-size: 18px;
                margin-bottom:10px;
            }
            .checkout-resume-row span
            {
                font-size: 12px;
            }
            #checkout-form
            {
                label
                {
                    font-size: 14px;
                }
            }
        }

        //restyle vadobay
        .panel-tertiary
        {
            //@include theme-gradient-horizontal();
            background-color:$white;
        }

        .panel-tertiary .top,
        .checkout-resume-block
        {
            border-color:transparent !important;
            position:relative;
            &:after
            {
                content:"";
                width:100%;
                height:1px;
                position:absolute;
                bottom:0;
                left:0;
                opacity:0.25;
                @include customize-background-color("dark");
            }
        }

        .checkout-resume-block:last-child:after
        {
            display:none;
        }

        .panel-tertiary .top .name,
        .panel-tertiary .top .credit
        {
            @include customize-color("dark");
        }

        .checkboxInfo
        {
            font-weight:bold;
            font-size:12px;
            color:$dark-gray;
        }
    }
</style>